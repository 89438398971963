.meal-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
    min-width: 250px;
}

.meal-options.show {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

/* Estilos para los botones dentro del menú */
.meal-options .btn-icon {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.meal-options .btn-icon i {
    width: 20px;
    margin-right: 0.75rem;
}

.meal-options .btn-icon:hover {
    background-color: #f8f9fa;
}

/* Asegurar que el contenedor tenga posición relativa */
.meal-header {
    position: relative;
}

.action-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

/* Hacemos el selector más específico para que tenga prioridad */
button.btn-icon.voice-input-button {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%) !important; /* Forzamos que siempre se aplique */
    z-index: 4;
    padding: 0.375rem 0.5rem;
    background: none;
    border: none;
    color: var(--blue-logo);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.voice-input-button i {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.btn-icon.voice-input-button:hover {
    color: var(--blue-logo-hover);
    transform: translateY(-50%) !important; /* Mantenemos la misma transformación en hover */
}

.btn-icon {
    padding: 0 0.25rem;
}

.form-control {
    position: relative;
    background-color: transparent;
    transition: padding 0.3s ease;
}

.form-control.recording {
    padding-left: 220px; /* Espacio para el visualizador */
}

.recording-controls {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 4;
}

.recording-timer {
  font-size: 0.85rem;
  color: var(--blue-logo);
  margin-right: 8px;
  font-family: monospace;
}

.form-control.recording {
  padding-right: 100px; /* Espacio para el timer y el botón */
}

/* Ajustamos el visualizador para que empiece después del timer */
canvas.recording-visualizer {
  width: calc(100% - 120px) !important; /* 100px del timer + 20px de margen */
  left: 10px !important;
}