.landing-page {
  height: 50vh;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.landing-text {
  text-align: left;
}

.landing-text p {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.landing-text .btn-primary {
  font-size: 1.2rem;
  padding: 0.75rem 2.5rem;
  background-color: #4a87e9;
  border-color: #4a87e9;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.landing-text .btn-primary:hover {
  background-color: #3a77d9;
  border-color: #3a77d9;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.landing-image {
  display: flex;
  justify-content: flex-end;
}

.landing-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 1399.98px) {
  .landing-image {
    justify-content: center;
  }
}

@media (max-width: 991.98px) {
  
  .landing-page {
    height: auto;
    padding: 2rem 0;
  }

  .landing-text {
    text-align: center;
    margin-bottom: 2rem;
  }

  .landing-text p {
    font-size: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .landing-text p {
    font-size: 1.2rem;
  }

  .landing-text .btn-primary {
    font-size: 1rem;
    padding: 0.5rem 2rem;
  }
}