.macros-form {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-label {
  font-size: 0.7em;
  font-weight: 400;
  color: #6c757d;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-save-goals {
  background: none;
  border: none;
  color: var(--blue-logo);
  padding: 0.5rem;
  transition: all 0.2s ease;
}

.btn-save-goals:hover {
  color: var(--blue-logo-hover);
  transform: translateY(-1px);
}

.btn-save-goals i {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .macros-form .col-md-2,
  .macros-form .col-calories,
  .macros-form .col-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  .btn-save-goals {
    width: 100%;
    text-align: center;
  }

  /* Nuevos estilos para BMR calculator en móvil */
  .input-panel .col {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .input-panel .form-select,
  .input-panel .form-control {
    width: 100%;
  }
}

.tdee-calculator {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-label {
  font-size: 0.7em;
  font-weight: 400;
  color: #6c757d;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.form-control:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .macros-form,
  .tdee-calculator {
    padding: 1rem;
  }
}

.formula-panel {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  margin-bottom: 1rem;
  width: 100%;
}

.formula-result {
  padding: 1rem;
}

.result-value {
  font-size: 1rem;
  font-weight: bold;
  color: var(--blue-logo);
}

/* Para cuando no hay resultado aún */
.result-value:empty::before {
  content: '-';
  color: #6c757d;
}

.input-panel {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.input-panel h4 {
  font-size: 0.8em;
  font-weight: 500;
  color: #495057;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}

.formula-panel h5 {
  font-size: 0.8em;
  font-weight: 500;
  color: #495057;
  margin-bottom: 1rem;
}

.formula-explanation {
  font-size: 0.75em;
  color: #6c757d;
  margin-top: 0.5rem;
  font-family: monospace;
}

.formula-constant {
  color: #6c757d;
}

.formula-variable {
  color: var(--blue-logo);
  font-weight: 500;
}

.formula-unit {
  color: #495057;
  font-size: 0.9em;
  font-style: italic;
}

/* Ocultar fórmulas en pantallas pequeñas */
@media (max-width: 768px) {
  .formula-explanation {
    display: none;
  }
  
  .formula-panel {
    padding: 1rem;  /* Reducir el padding también */
  }
  
  .result-value {
    font-size: 0.9rem;  /* Resultado más pequeño en móvil */
  }
}

.multiplier {
  color: var(--blue-logo);
  font-weight: 500;
}

.exercise-calories {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.exercise-result {
  color: var(--blue-logo);
  font-size: 1.1rem;
  font-weight: 500;
}

.calories-label {
  color: #6c757d;
  font-size: 0.8em;
}

select option {
  font-size: 0.9em;
}

select option .multiplier {
  color: var(--blue-logo);
} 