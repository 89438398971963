.synonym-toggle {
  background: transparent;
  border: none;
  padding: 2px 4px;
  margin-right: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #666;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
}

.synonym-toggle:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.synonym-toggle:focus {
  outline: none;
}