:root {
  --misterfit-blue: #4a87e9;
}

.mr-fit-container {
  padding: 0rem;
  border-radius: 0.5rem;
  /* border: 1px solid var(--misterfit-blue); */
}

.daily-analysis {
  margin-bottom: 1rem;
}

.daily-analysis h4 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: var(--misterfit-blue);
}

.daily-analysis h5 {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  color: var(--misterfit-blue);
}

.text-success {
  color: #eddde1;
}

.daily-analysis .text-primary {
  color: #007bff;
}

.daily-analysis .text-muted {
  color: #6c757d;
}

/* Estilos para el spinner de carga */
.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

/* Estilos para el contenedor de respuesta */
.response-container {
  border: 1px solid #000000;
  background-color: #f8f9fa;
  border-radius: 8px;
  font-size: 0.85rem;
  color: #666;
}

.response-container ul {
  padding-left: 2rem;
  list-style: disc;
}

.response-container li {
  margin-bottom: 0.5rem;
}

.input-group .btn-primary {
  background-color: #28a745;
  border-color: #28a745;
}

.input-group .btn-primary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.food-groups-section {
  background-color: rgba(74, 135, 233, 0.1);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.food-groups-section h5 {
  font-weight: 600;
  border-bottom: 2px solid var(--misterfit-blue);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--misterfit-blue);
}

.food-group {
  background-color: white;
  border-radius: 8px;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.food-group:last-child {
  margin-bottom: 0;
}

.food-group h6 {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}

.food-group h6 i {
  color: #666;
  width: 20px;
}

.food-group .metric-label {
  font-size: 0.85rem;
  color: #666;
}

.food-group .text-success {
  color: #28a745 !important;
}

.food-group .text-danger {
  color: #dc3545 !important;
}

.food-group p {
  color: #666;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #eee;
}

.food-group .fa-check-circle {
  font-size: 1rem;
}

/* Ajustes responsive */
@media (max-width: 768px) {
  .food-group {
    padding: 0.5rem;
  }

  .food-group h6 {
    font-size: 0.85rem;
  }

  .food-group .metric-label {
    font-size: 0.75rem;
  }

  .food-group .fa-check-circle {
    font-size: 0.9rem;
  }

  .food-group p {
    font-size: 0.8rem;
  }
}

.text-warning {
  color: #ffc107 !important;
}

.daily-comment {
  color: #666;
  font-size: 0.85rem;
}

/* Ajustes responsive */
@media (max-width: 768px) {
  .daily-comment {
    font-size: 0.8rem;
  }
}

.btn-icon {
  background: none;
  border: none;
  color: var(--misterfit-blue);
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
}

.btn-icon:hover {
  color: #3a77d9;
  transform: translateY(-1px);
}

.btn-icon:disabled {
  color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.input-group {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
}

.input-group .form-control {
  border: none;
  padding: 0.75rem 1rem;
}

.input-group .form-control:focus {
  box-shadow: none;
}

/* Lista sin bullets para los análisis */
.analysis-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem;
}

/* Estilos para las listas de análisis con iconos */
.analysis-list-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.analysis-list-item i {
  margin-right: 0.5rem;
  margin-top: 0.25rem;
}

/* Ajustes específicos para Ask Misterfit */
.daily-analysis .recording-controls {
  right: 60px; /* Aumentamos el espacio para el timer */
}

.daily-analysis .voice-input-button {
  right: 40px; /* Mantenemos el botón de micrófono en su lugar */
}

.daily-analysis .btn-icon[type="submit"] {
  right: 0px;
}

.daily-analysis .input-group {
  position: relative;
}

/* Ajustamos el visualizador para que no se solape con el timer */
.daily-analysis canvas.recording-visualizer {
  width: calc(100% - 140px) !important; /* Aumentamos el espacio reservado */
  left: 10px !important;
}

.food-group-header {
  transition: all 0.2s ease;
}

.food-group-header:hover {
  opacity: 0.9;
}

.food-group-header h6 {
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.food-group-header h6 i {
  color: #666;
  width: 20px;
}

.metrics-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.food-group-content {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid #eee;
}

.food-group-content p {
  color: #666;
  font-size: 0.85rem;
  margin: 0;
}

.metric-label {
  font-size: 0.85rem;
  color: #666;
  white-space: nowrap;
}

.fa-chevron-up,
.fa-chevron-down {
  font-size: 0.8rem;
  color: #6c757d;
}

@media (max-width: 768px) {
  .food-group-header h6 {
    font-size: 0.85rem;
  }
  
  .metric-label {
    font-size: 0.75rem;
  }
  
  .food-group-content p {
    font-size: 0.8rem;
  }
  
  .food-group .fa-check-circle {
    font-size: 0.9rem;
  }
} 