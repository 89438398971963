/* brand blue #4a87e9 */
/* another nice blue used for navbar: 007bff8*/

:root {
  --blue-logo: #4a87e9;
  --blue-logo-light: #409de3;
  --blue-logo-hover: #0056b3;
}

.App-header {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

textarea {
  width: 80%;
  height: 80px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: #000;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #333;
}

.navbar {
  margin-top: 10px;
}

.navbar-brand {
  color: var(--blue-logo) !important;
  font-size: 1.2rem;
}

.nav-link {
  color: var(--blue-logo) !important;
  font-size: 0.8em; /* Reduce el tamaño de la fuente */
  display: flex;
  align-items: center;
  transition: font-weight 0.2s ease;
}

.nav-link i {
  margin-right: 5px; /* Espacio entre el icono y el texto */
}

.navbar .nav-link.active {
  color: var(--blue-logo) !important;
  font-weight: bold;
}

.navbar .nav-link:hover {
  color: var(--blue-logo-hover) !important;
}


.main-container {
  width: 80%;
  margin: 0px auto;
  text-align: left;
}

/* Ajustes para pantallas medianas */
@media (max-width: 991.98px) {
  .main-container {
    width: 90%;
  }
}

/* Ajustes para móviles */
@media (max-width: 767.98px) {
  .main-container {
    width: 98%;
    margin: 0px auto;
  }
}

/* Ajustes para móviles muy pequeños */
@media (max-width: 575.98px) {
  .main-container {
    width: 98%;
    margin: 0px auto;
  }
}

.my-day-log-container {
  margin: 20px auto;
}

.header-row {
  font-weight: bold;
  background-color: #ffffff;
  border-bottom: 1px #dee2e6; /* add solid to make it visible */
  padding: 0px 0;
  color: var(--blue-logo);
  font-size: 0.9em;
  text-align: center;
}


.header-row .col {
  text-align: center;
  color: #343a40;
}

.meal-section {
  margin-bottom: 20px;
}
.meal-header h3 {
  font-size: 1.3em;
  color: var(--blue-logo);
}

.meal-header {
  align-items: center;
  font-size: 0.8em;
}

.header-row-total-macros {
  font-size: 0.8em;
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6;
  padding: 0px 0;
  color: #000000; 
  text-align: center;
}

.meal-row {
  font-size: 0.8em;
}

.macro-total-cell {
  font-weight: bold;
  color: #007bff;
  text-align: center;
}

.macro-total-cell-day {
  font-weight: bold;
  color: #000000;
  text-align: center;
}

.macro-cell {
  text-align: center;
}

.btn-trash-meal {
  background: none;
  font-size: 1em;
  cursor: pointer;
  color: var(--blue-logo); /* Blue */
}

.btn-trash-food {
  background: none;
  font-size: 1em;
  cursor: pointer;
}

.btn-send-meal {
  font-size: 1em;
  cursor: pointer;
  color: var(--blue-logo);
}

.add-food-row {
  margin-top: 2px;
}

.add-food-text {
  font-size: 0.8em;
  color: #007bff;
  cursor: pointer;
}

.btn-primary {
  background-color: var(--blue-logo);
  border-color: var(--blue-logo);  
  color: white;
}

.btn-primary:hover {
  opacity: 0.9;
}

.add-food-text:hover {
  color: var(--blue-logo-hover);
}

.btn-black {
  background-color: #000000; /* Fondo negro */
  color: white; /* Texto blanco */
  border: 1px solid #000000 !important; /* Borde negro */
}

.btn-black:hover {
  background-color: #333333; /* Fondo gris oscuro al pasar el ratón */
}

/* Logo styling */
.logo-img {
  height: 30px;
  width: auto;
  margin-right: 10px;
}

/* Displays of general info */
/***************************/
/* Scoring */
.summary-container {
  display: flex;
  padding-top: 20px;
  justify-content: flex-start; /* Align items at the start */
  align-items: center;
  position: relative;
  padding-left: 0px; /* Adjust according to the width of the macro-score-wrapper */
}

.macro-score-wrapper {
  position: absolute;
  padding-top: 0px;
  left: 100px;
  width: 150px; /* Adjust the size as needed */
  height: 150px;
  z-index: 1;
}

.macro-score-label {
  font-size: 0.8em;
  color: #fff;
  margin-bottom: 5px;
}

.macro-score {
  font-size: 2em;
  font-weight: bold;
}

.macro-score-container {
  text-align: center;
  background-color: #ffffff;
  padding-left: 0px;
  padding-top:0px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.macro-score-chart {
  width: 100px; /* Ajusta el tamaño según sea necesario */
  height: 100px;
  margin: 0 auto;
}


/* Daily numeric totals */
.total-macros {
  margin-top: 20px;
}

.total-macros {
  width: 100%; /* Keep the width at 100% of the parent container */
}
.total-macros-row {
  font-size: 0.8em;

}

.total-macros-percentage-row {
  font-size: 0.8em;
  margin-top: -1px; /* Más cerca del total */
  margin-bottom: 2px; /* Más cerca del total */
}

.total-macros-row-title{
  font-size: 1em;
  text-align: right
}

.debug-info {
  margin-top: 20px;
  width: 80%;
  margin: 0 auto;
  text-align: left;
}


/*.macro-total-cell, .percentage-cell {
  text-align: center;
}*/

.percentage-cell {
  color: #6c757d; /* Gris */
  font-size: 0.9em; /* Más pequeño */
  text-align: center;
}

.empty-percentage-cell {
  display: inline-block;
  width: 100%; /* or a specific width */
  height: 1em; /* or a specific height */
  margin-top: -1px; /* Más cerca del total */
  margin-bottom: 10px; /* Más cerca del total */
  visibility: hidden; /* Makes the element invisible while still occupying space */
}





@media (max-width: 767.98px) {
  .macro-score-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 20px; /* Adjust as needed for spacing */
  }

}

/* Estilos para la tabla de alimentos */
.food-row {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}

.food-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.food-row:nth-child(even) {
  background-color: #fff;
}

/* Añade esto al final de tu archivo App.css */

.my-food-container {
  font-size: 1em; /* Letra más pequeña */
}


.food-row {
  padding: 1px 0;
  border-bottom: 1px solid #ddd;
  font-size: 0.8em; /* Letra aún más pequeña para los datos */
}

.food-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.food-row:nth-child(even) {
  background-color: #fff;
}

.my-food-container {
  text-align: left;
}

.food-name-cell {
  text-align: left;
}

.sort-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0 5px;
  color: var(--blue-logo);
}

.sort-btn:hover {
  color: var(--blue-logo-hover);
}

.action-button-header {
  margin-left: 2px;
  margin-right: 2px;
  line-height: 1;
  vertical-align: middle;
}

.action-button {
  padding: 0;
  margin-left: 2px;
  margin-right: 2px;
  line-height: 1;
  vertical-align: middle;
}

.action-icon {
  font-size: 13px;
}

.action-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MacroProgressBars.css */
.macro-progress-bars {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.macro-bar {
  font-size: 0.9em;
  margin-bottom: 1rem;
}

.progress-container {
  height: 25px;
  position: relative;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  overflow: hidden;
}

.progress-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9ecef;
}

.progress-fill {
  height: 100%;
  position: relative;
  transition: width 0.6s ease;
}

.progress-fill.exceeded {
  background-image: linear-gradient(45deg, 
    rgba(255, 255, 255, 0.15) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, 0.15) 50%, 
    rgba(255, 255, 255, 0.15) 75%, 
    transparent 75%, 
    transparent 100%
  );
  background-size: 40px 40px;
}

.progress-100-mark {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #000;
}

.progress-label {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}


.input-container {
  position: relative;
}

.inline-suggestion {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.435rem 0.75rem;
  height: 100%;
  width: 100%;
  color: transparent;
  pointer-events: none;
  white-space: pre;
  overflow: hidden;
}

.inline-suggestion .suggested-text {
  color: grey;
}

.form-control {
  position: relative;
  background-color: transparent;
}

@media (max-width: 767.98px) {

  /* Estilos para el botón de icono */
  .btn-icon {
    background: none;
    border: none;
    padding: 0;
    font-size: 1em;
    cursor: pointer;
  }

  .btn-icon:hover {
    background: none; /* Asegúrate de que no haya fondo al pasar el mouse */
    color: var(--blue-logo-hover); /* Cambia el color al pasar el mouse */
  }
}

/* Estilo específico para los botones de iconos del meal header */
.meal-header .btn-icon {
  background: none;
  border: none;
  padding: 6px;
  margin: 0 4px;
  color: var(--blue-logo);
  transition: all 0.2s ease;
}

@media (max-width: 767.98px) {
  .meal-header .btn-icon {
    padding: 4px;
    margin: 0 2px;
  }
}

@media (max-width: 575.98px) {
  .meal-header .btn-icon {
    padding: 2px;
    margin: 0 1px;
  }
}

/* Mantener los estilos base para .btn-icon */
.btn-icon {
  padding: 6px;
  margin: 0 4px;
  background: none;
  border: none;
  color: var(--blue-logo);
  transition: all 0.2s ease;
}

@media (max-width: 767.98px) {
  .btn-icon {
    padding: 4px;
    margin: 0 2px;
  }
}

@media (max-width: 575.98px) {
  .btn-icon {
    padding: 2px;
    margin: 0 1px;
  }
}

.macro-icon {
    color: var(--blue-logo);
}

.editable-food-log-group {
  transition: font-weight 0.2s ease;
}

.editable-food-log-group:hover {
  font-weight: bold;
  cursor: pointer;
}

.react-datepicker__day--highlighted,
.highlighted-date {
    background-color: var(--blue-logo-light) !important;
    color: #ffffff !important;  /* Cambiamos a negro */
}

.react-datepicker__day--highlighted:hover,
.highlighted-date:hover {
    background-color: var(--blue-logo-hover) !important;
}
